

export const promotionList = [
    {
      id: 1,
      title: "Deposit Bonus",
      desc: "Up to 150% bonus on 1st",
      alt: "",
      img_url: "/images/depositbonus.jpg",
      promoClick: "depositbonus"
    },
    {
      id: 2,
      title: "Payday Bonus",
      desc: "Payday Bonus at SG8 Casino",
      alt: "",
      img_url: "/images/paydaybonus.jpg",
      promoClick: "depositbonus"
    },
    {
      id: 3,
      title: "Birthday Bonus",
      desc: "Come and Celebrate your Birthday",
      alt: "",
      img_url: "/images/birthday-bonus.jpg",
      promoClick: "depositbonus"
    }
  ];